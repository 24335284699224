	/* ---------------------------------- */
	/* Offsets
	------------------------------------- */
	/* Margin Top */
	.margin-top-0   { margin-top: 0px  !important; }
	.margin-top-1   { margin-top: 1px  !important; }
	.margin-top-2   { margin-top: 2px  !important; }
	.margin-top-3   { margin-top: 3px  !important; }
	.margin-top-4   { margin-top: 4px  !important; }
	.margin-top-5   { margin-top: 5px  !important; }
	.margin-top-6   { margin-top: 6px  !important; }
	.margin-top-7   { margin-top: 7px  !important; }
	.margin-top-8   { margin-top: 8px  !important; }
	.margin-top-9   { margin-top: 9px  !important; }
	.margin-top-10  { margin-top: 10px !important; }
	.margin-top-11  { margin-top: 11px !important; }
	.margin-top-12  { margin-top: 12px !important; }
	.margin-top-13  { margin-top: 13px !important; }
	.margin-top-14  { margin-top: 14px !important; }
	.margin-top-15  { margin-top: 15px !important; }
	.margin-top-16  { margin-top: 16px !important; }
	.margin-top-17  { margin-top: 17px !important; }
	.margin-top-18  { margin-top: 18px !important; }
	.margin-top-19  { margin-top: 19px !important; }
	.margin-top-20  { margin-top: 20px !important; }
	.margin-top-21  { margin-top: 21px !important; }
	.margin-top-22  { margin-top: 22px !important; }
	.margin-top-23  { margin-top: 23px !important; }
	.margin-top-24  { margin-top: 24px !important; }
	.margin-top-25  { margin-top: 25px !important; }
	.margin-top-26  { margin-top: 26px !important; }
	.margin-top-27  { margin-top: 27px !important; }
	.margin-top-28  { margin-top: 28px !important; }
	.margin-top-29  { margin-top: 29px !important; }
	.margin-top-30  { margin-top: 30px !important; }
	.margin-top-31  { margin-top: 31px !important; }
	.margin-top-32  { margin-top: 32px !important; }
	.margin-top-33  { margin-top: 33px !important; }
	.margin-top-34  { margin-top: 34px !important; }
	.margin-top-35  { margin-top: 35px !important; }
	.margin-top-36  { margin-top: 36px !important; }
	.margin-top-37  { margin-top: 37px !important; }
	.margin-top-38  { margin-top: 38px !important; }
	.margin-top-39  { margin-top: 39px !important; }
	.margin-top-40  { margin-top: 40px !important; }
	.margin-top-41  { margin-top: 41px !important; }
	.margin-top-42  { margin-top: 42px !important; }
	.margin-top-43  { margin-top: 43px !important; }
	.margin-top-44  { margin-top: 44px !important; }
	.margin-top-45  { margin-top: 45px !important; }
	.margin-top-46  { margin-top: 46px !important; }
	.margin-top-47  { margin-top: 47px !important; }
	.margin-top-48  { margin-top: 48px !important; }
	.margin-top-49  { margin-top: 49px !important; }
	.margin-top-50  { margin-top: 50px !important; }
	.margin-top-51  { margin-top: 51px !important; }
	.margin-top-52  { margin-top: 52px !important; }
	.margin-top-53  { margin-top: 53px !important; }
	.margin-top-54  { margin-top: 54px !important; }
	.margin-top-55  { margin-top: 55px !important; }
	.margin-top-56  { margin-top: 56px !important; }
	.margin-top-57  { margin-top: 57px !important; }
	.margin-top-58  { margin-top: 58px !important; }
	.margin-top-59  { margin-top: 59px !important; }
	.margin-top-60  { margin-top: 60px !important; }
	.margin-top-61  { margin-top: 61px !important; }
	.margin-top-62  { margin-top: 62px !important; }
	.margin-top-63  { margin-top: 63px !important; }
	.margin-top-64  { margin-top: 64px !important; }
	.margin-top-65  { margin-top: 65px !important; }
	.margin-top-66  { margin-top: 66px !important; }
	.margin-top-67  { margin-top: 67px !important; }
	.margin-top-68  { margin-top: 68px !important; }
	.margin-top-69  { margin-top: 69px !important; }
	.margin-top-70  { margin-top: 70px !important; }
	.margin-top-71  { margin-top: 71px !important; }
	.margin-top-72  { margin-top: 72px !important; }
	.margin-top-73  { margin-top: 73px !important; }
	.margin-top-74  { margin-top: 74px !important; }
	.margin-top-75  { margin-top: 75px !important; }
	.margin-top-76  { margin-top: 76px !important; }
	.margin-top-77  { margin-top: 77px !important; }
	.margin-top-78  { margin-top: 78px !important; }
	.margin-top-79  { margin-top: 79px !important; }
	.margin-top-80  { margin-top: 80px !important; }
	.margin-top-81  { margin-top: 81px !important; }
	.margin-top-82  { margin-top: 82px !important; }
	.margin-top-83  { margin-top: 83px !important; }
	.margin-top-84  { margin-top: 84px !important; }
	.margin-top-85  { margin-top: 85px !important; }
	.margin-top-86  { margin-top: 86px !important; }
	.margin-top-87  { margin-top: 87px !important; }
	.margin-top-88  { margin-top: 88px !important; }
	.margin-top-89  { margin-top: 89px !important; }
	.margin-top-90  { margin-top: 90px !important; }
	.margin-top-91  { margin-top: 91px !important; }
	.margin-top-92  { margin-top: 92px !important; }
	.margin-top-93  { margin-top: 93px !important; }
	.margin-top-94  { margin-top: 94px !important; }
	.margin-top-95  { margin-top: 95px !important; }
	.margin-top-96  { margin-top: 96px !important; }
	.margin-top-97  { margin-top: 97px !important; }
	.margin-top-98  { margin-top: 98px !important; }
	.margin-top-99  { margin-top: 99px !important; }
	.margin-top-100 { margin-top: 100px !important; }

	/* Margin Bottom */
	.margin-bottom-0   { margin-bottom: 0px  !important; }
	.margin-bottom-1   { margin-bottom: 1px  !important; }
	.margin-bottom-2   { margin-bottom: 2px  !important; }
	.margin-bottom-3   { margin-bottom: 3px  !important; }
	.margin-bottom-4   { margin-bottom: 4px  !important; }
	.margin-bottom-5   { margin-bottom: 5px  !important; }
	.margin-bottom-6   { margin-bottom: 6px  !important; }
	.margin-bottom-7   { margin-bottom: 7px  !important; }
	.margin-bottom-8   { margin-bottom: 8px  !important; }
	.margin-bottom-9   { margin-bottom: 9px  !important; }
	.margin-bottom-10  { margin-bottom: 10px !important; }
	.margin-bottom-11  { margin-bottom: 11px !important; }
	.margin-bottom-12  { margin-bottom: 12px !important; }
	.margin-bottom-13  { margin-bottom: 13px !important; }
	.margin-bottom-14  { margin-bottom: 14px !important; }
	.margin-bottom-15  { margin-bottom: 15px !important; }
	.margin-bottom-16  { margin-bottom: 16px !important; }
	.margin-bottom-17  { margin-bottom: 17px !important; }
	.margin-bottom-18  { margin-bottom: 18px !important; }
	.margin-bottom-19  { margin-bottom: 19px !important; }
	.margin-bottom-20  { margin-bottom: 20px !important; }
	.margin-bottom-21  { margin-bottom: 21px !important; }
	.margin-bottom-22  { margin-bottom: 22px !important; }
	.margin-bottom-23  { margin-bottom: 23px !important; }
	.margin-bottom-24  { margin-bottom: 24px !important; }
	.margin-bottom-25  { margin-bottom: 25px !important; }
	.margin-bottom-26  { margin-bottom: 26px !important; }
	.margin-bottom-27  { margin-bottom: 27px !important; }
	.margin-bottom-28  { margin-bottom: 28px !important; }
	.margin-bottom-29  { margin-bottom: 29px !important; }
	.margin-bottom-30  { margin-bottom: 30px !important; }
	.margin-bottom-31  { margin-bottom: 31px !important; }
	.margin-bottom-32  { margin-bottom: 32px !important; }
	.margin-bottom-33  { margin-bottom: 33px !important; }
	.margin-bottom-34  { margin-bottom: 34px !important; }
	.margin-bottom-35  { margin-bottom: 35px !important; }
	.margin-bottom-36  { margin-bottom: 36px !important; }
	.margin-bottom-37  { margin-bottom: 37px !important; }
	.margin-bottom-38  { margin-bottom: 38px !important; }
	.margin-bottom-39  { margin-bottom: 39px !important; }
	.margin-bottom-40  { margin-bottom: 40px !important; }
	.margin-bottom-41  { margin-bottom: 41px !important; }
	.margin-bottom-42  { margin-bottom: 42px !important; }
	.margin-bottom-43  { margin-bottom: 43px !important; }
	.margin-bottom-44  { margin-bottom: 44px !important; }
	.margin-bottom-45  { margin-bottom: 45px !important; }
	.margin-bottom-46  { margin-bottom: 46px !important; }
	.margin-bottom-47  { margin-bottom: 47px !important; }
	.margin-bottom-48  { margin-bottom: 48px !important; }
	.margin-bottom-49  { margin-bottom: 49px !important; }
	.margin-bottom-50  { margin-bottom: 50px !important; }
	.margin-bottom-51  { margin-bottom: 51px !important; }
	.margin-bottom-52  { margin-bottom: 52px !important; }
	.margin-bottom-53  { margin-bottom: 53px !important; }
	.margin-bottom-54  { margin-bottom: 54px !important; }
	.margin-bottom-55  { margin-bottom: 55px !important; }
	.margin-bottom-56  { margin-bottom: 56px !important; }
	.margin-bottom-57  { margin-bottom: 57px !important; }
	.margin-bottom-58  { margin-bottom: 58px !important; }
	.margin-bottom-59  { margin-bottom: 59px !important; }
	.margin-bottom-60  { margin-bottom: 60px !important; }
	.margin-bottom-61  { margin-bottom: 61px !important; }
	.margin-bottom-62  { margin-bottom: 62px !important; }
	.margin-bottom-63  { margin-bottom: 63px !important; }
	.margin-bottom-64  { margin-bottom: 64px !important; }
	.margin-bottom-65  { margin-bottom: 65px !important; }
	.margin-bottom-66  { margin-bottom: 66px !important; }
	.margin-bottom-67  { margin-bottom: 67px !important; }
	.margin-bottom-68  { margin-bottom: 68px !important; }
	.margin-bottom-69  { margin-bottom: 69px !important; }
	.margin-bottom-70  { margin-bottom: 70px !important; }
	.margin-bottom-71  { margin-bottom: 71px !important; }
	.margin-bottom-72  { margin-bottom: 72px !important; }
	.margin-bottom-73  { margin-bottom: 73px !important; }
	.margin-bottom-74  { margin-bottom: 74px !important; }
	.margin-bottom-75  { margin-bottom: 75px !important; }
	.margin-bottom-76  { margin-bottom: 76px !important; }
	.margin-bottom-77  { margin-bottom: 77px !important; }
	.margin-bottom-78  { margin-bottom: 78px !important; }
	.margin-bottom-79  { margin-bottom: 79px !important; }
	.margin-bottom-80  { margin-bottom: 80px !important; }
	.margin-bottom-81  { margin-bottom: 81px !important; }
	.margin-bottom-82  { margin-bottom: 82px !important; }
	.margin-bottom-83  { margin-bottom: 83px !important; }
	.margin-bottom-84  { margin-bottom: 84px !important; }
	.margin-bottom-85  { margin-bottom: 85px !important; }
	.margin-bottom-86  { margin-bottom: 86px !important; }
	.margin-bottom-87  { margin-bottom: 87px !important; }
	.margin-bottom-88  { margin-bottom: 88px !important; }
	.margin-bottom-89  { margin-bottom: 89px !important; }
	.margin-bottom-90  { margin-bottom: 90px !important; }
	.margin-bottom-91  { margin-bottom: 91px !important; }
	.margin-bottom-92  { margin-bottom: 92px !important; }
	.margin-bottom-93  { margin-bottom: 93px !important; }
	.margin-bottom-94  { margin-bottom: 94px !important; }
	.margin-bottom-95  { margin-bottom: 95px !important; }
	.margin-bottom-96  { margin-bottom: 96px !important; }
	.margin-bottom-97  { margin-bottom: 97px !important; }
	.margin-bottom-98  { margin-bottom: 98px !important; }
	.margin-bottom-99  { margin-bottom: 99px !important; }
	.margin-bottom-100 { margin-bottom: 100px !important; }

	/* Margin Left */
	.margin-left-0   { margin-left: 0px  !important; }
	.margin-left-1   { margin-left: 1px  !important; }
	.margin-left-2   { margin-left: 2px  !important; }
	.margin-left-3   { margin-left: 3px  !important; }
	.margin-left-4   { margin-left: 4px  !important; }
	.margin-left-5   { margin-left: 5px  !important; }
	.margin-left-6   { margin-left: 6px  !important; }
	.margin-left-7   { margin-left: 7px  !important; }
	.margin-left-8   { margin-left: 8px  !important; }
	.margin-left-9   { margin-left: 9px  !important; }
	.margin-left-10  { margin-left: 10px !important; }
	.margin-left-11  { margin-left: 11px !important; }
	.margin-left-12  { margin-left: 12px !important; }
	.margin-left-13  { margin-left: 13px !important; }
	.margin-left-14  { margin-left: 14px !important; }
	.margin-left-15  { margin-left: 15px !important; }
	.margin-left-16  { margin-left: 16px !important; }
	.margin-left-17  { margin-left: 17px !important; }
	.margin-left-18  { margin-left: 18px !important; }
	.margin-left-19  { margin-left: 19px !important; }
	.margin-left-20  { margin-left: 20px !important; }
	.margin-left-21  { margin-left: 21px !important; }
	.margin-left-22  { margin-left: 22px !important; }
	.margin-left-23  { margin-left: 23px !important; }
	.margin-left-24  { margin-left: 24px !important; }
	.margin-left-25  { margin-left: 25px !important; }
	.margin-left-26  { margin-left: 26px !important; }
	.margin-left-27  { margin-left: 27px !important; }
	.margin-left-28  { margin-left: 28px !important; }
	.margin-left-29  { margin-left: 29px !important; }
	.margin-left-30  { margin-left: 30px !important; }
	.margin-left-31  { margin-left: 31px !important; }
	.margin-left-32  { margin-left: 32px !important; }
	.margin-left-33  { margin-left: 33px !important; }
	.margin-left-34  { margin-left: 34px !important; }
	.margin-left-35  { margin-left: 35px !important; }
	.margin-left-36  { margin-left: 36px !important; }
	.margin-left-37  { margin-left: 37px !important; }
	.margin-left-38  { margin-left: 38px !important; }
	.margin-left-39  { margin-left: 39px !important; }
	.margin-left-40  { margin-left: 40px !important; }
	.margin-left-41  { margin-left: 41px !important; }
	.margin-left-42  { margin-left: 42px !important; }
	.margin-left-43  { margin-left: 43px !important; }
	.margin-left-44  { margin-left: 44px !important; }
	.margin-left-45  { margin-left: 45px !important; }
	.margin-left-46  { margin-left: 46px !important; }
	.margin-left-47  { margin-left: 47px !important; }
	.margin-left-48  { margin-left: 48px !important; }
	.margin-left-49  { margin-left: 49px !important; }
	.margin-left-50  { margin-left: 50px !important; }
	.margin-left-51  { margin-left: 51px !important; }
	.margin-left-52  { margin-left: 52px !important; }
	.margin-left-53  { margin-left: 53px !important; }
	.margin-left-54  { margin-left: 54px !important; }
	.margin-left-55  { margin-left: 55px !important; }
	.margin-left-56  { margin-left: 56px !important; }
	.margin-left-57  { margin-left: 57px !important; }
	.margin-left-58  { margin-left: 58px !important; }
	.margin-left-59  { margin-left: 59px !important; }
	.margin-left-60  { margin-left: 60px !important; }
	.margin-left-61  { margin-left: 61px !important; }
	.margin-left-62  { margin-left: 62px !important; }
	.margin-left-63  { margin-left: 63px !important; }
	.margin-left-64  { margin-left: 64px !important; }
	.margin-left-65  { margin-left: 65px !important; }
	.margin-left-66  { margin-left: 66px !important; }
	.margin-left-67  { margin-left: 67px !important; }
	.margin-left-68  { margin-left: 68px !important; }
	.margin-left-69  { margin-left: 69px !important; }
	.margin-left-70  { margin-left: 70px !important; }
	.margin-left-71  { margin-left: 71px !important; }
	.margin-left-72  { margin-left: 72px !important; }
	.margin-left-73  { margin-left: 73px !important; }
	.margin-left-74  { margin-left: 74px !important; }
	.margin-left-75  { margin-left: 75px !important; }
	.margin-left-76  { margin-left: 76px !important; }
	.margin-left-77  { margin-left: 77px !important; }
	.margin-left-78  { margin-left: 78px !important; }
	.margin-left-79  { margin-left: 79px !important; }
	.margin-left-80  { margin-left: 80px !important; }
	.margin-left-81  { margin-left: 81px !important; }
	.margin-left-82  { margin-left: 82px !important; }
	.margin-left-83  { margin-left: 83px !important; }
	.margin-left-84  { margin-left: 84px !important; }
	.margin-left-85  { margin-left: 85px !important; }
	.margin-left-86  { margin-left: 86px !important; }
	.margin-left-87  { margin-left: 87px !important; }
	.margin-left-88  { margin-left: 88px !important; }
	.margin-left-89  { margin-left: 89px !important; }
	.margin-left-90  { margin-left: 90px !important; }
	.margin-left-91  { margin-left: 91px !important; }
	.margin-left-92  { margin-left: 92px !important; }
	.margin-left-93  { margin-left: 93px !important; }
	.margin-left-94  { margin-left: 94px !important; }
	.margin-left-95  { margin-left: 95px !important; }
	.margin-left-96  { margin-left: 96px !important; }
	.margin-left-97  { margin-left: 97px !important; }
	.margin-left-98  { margin-left: 98px !important; }
	.margin-left-99  { margin-left: 99px !important; }
	.margin-left-100 { margin-left: 100px !important; }

	/* Margin Right */
	.margin-right-0   { margin-right: 0px  !important; }
	.margin-right-1   { margin-right: 1px  !important; }
	.margin-right-2   { margin-right: 2px  !important; }
	.margin-right-3   { margin-right: 3px  !important; }
	.margin-right-4   { margin-right: 4px  !important; }
	.margin-right-5   { margin-right: 5px  !important; }
	.margin-right-6   { margin-right: 6px  !important; }
	.margin-right-7   { margin-right: 7px  !important; }
	.margin-right-8   { margin-right: 8px  !important; }
	.margin-right-9   { margin-right: 9px  !important; }
	.margin-right-10  { margin-right: 10px !important; }
	.margin-right-11  { margin-right: 11px !important; }
	.margin-right-12  { margin-right: 12px !important; }
	.margin-right-13  { margin-right: 13px !important; }
	.margin-right-14  { margin-right: 14px !important; }
	.margin-right-15  { margin-right: 15px !important; }
	.margin-right-16  { margin-right: 16px !important; }
	.margin-right-17  { margin-right: 17px !important; }
	.margin-right-18  { margin-right: 18px !important; }
	.margin-right-19  { margin-right: 19px !important; }
	.margin-right-20  { margin-right: 20px !important; }
	.margin-right-21  { margin-right: 21px !important; }
	.margin-right-22  { margin-right: 22px !important; }
	.margin-right-23  { margin-right: 23px !important; }
	.margin-right-24  { margin-right: 24px !important; }
	.margin-right-25  { margin-right: 25px !important; }
	.margin-right-26  { margin-right: 26px !important; }
	.margin-right-27  { margin-right: 27px !important; }
	.margin-right-28  { margin-right: 28px !important; }
	.margin-right-29  { margin-right: 29px !important; }
	.margin-right-30  { margin-right: 30px !important; }
	.margin-right-31  { margin-right: 31px !important; }
	.margin-right-32  { margin-right: 32px !important; }
	.margin-right-33  { margin-right: 33px !important; }
	.margin-right-34  { margin-right: 34px !important; }
	.margin-right-35  { margin-right: 35px !important; }
	.margin-right-36  { margin-right: 36px !important; }
	.margin-right-37  { margin-right: 37px !important; }
	.margin-right-38  { margin-right: 38px !important; }
	.margin-right-39  { margin-right: 39px !important; }
	.margin-right-40  { margin-right: 40px !important; }
	.margin-right-41  { margin-right: 41px !important; }
	.margin-right-42  { margin-right: 42px !important; }
	.margin-right-43  { margin-right: 43px !important; }
	.margin-right-44  { margin-right: 44px !important; }
	.margin-right-45  { margin-right: 45px !important; }
	.margin-right-46  { margin-right: 46px !important; }
	.margin-right-47  { margin-right: 47px !important; }
	.margin-right-48  { margin-right: 48px !important; }
	.margin-right-49  { margin-right: 49px !important; }
	.margin-right-50  { margin-right: 50px !important; }
	.margin-right-51  { margin-right: 51px !important; }
	.margin-right-52  { margin-right: 52px !important; }
	.margin-right-53  { margin-right: 53px !important; }
	.margin-right-54  { margin-right: 54px !important; }
	.margin-right-55  { margin-right: 55px !important; }
	.margin-right-56  { margin-right: 56px !important; }
	.margin-right-57  { margin-right: 57px !important; }
	.margin-right-58  { margin-right: 58px !important; }
	.margin-right-59  { margin-right: 59px !important; }
	.margin-right-60  { margin-right: 60px !important; }
	.margin-right-61  { margin-right: 61px !important; }
	.margin-right-62  { margin-right: 62px !important; }
	.margin-right-63  { margin-right: 63px !important; }
	.margin-right-64  { margin-right: 64px !important; }
	.margin-right-65  { margin-right: 65px !important; }
	.margin-right-66  { margin-right: 66px !important; }
	.margin-right-67  { margin-right: 67px !important; }
	.margin-right-68  { margin-right: 68px !important; }
	.margin-right-69  { margin-right: 69px !important; }
	.margin-right-70  { margin-right: 70px !important; }
	.margin-right-71  { margin-right: 71px !important; }
	.margin-right-72  { margin-right: 72px !important; }
	.margin-right-73  { margin-right: 73px !important; }
	.margin-right-74  { margin-right: 74px !important; }
	.margin-right-75  { margin-right: 75px !important; }
	.margin-right-76  { margin-right: 76px !important; }
	.margin-right-77  { margin-right: 77px !important; }
	.margin-right-78  { margin-right: 78px !important; }
	.margin-right-79  { margin-right: 79px !important; }
	.margin-right-80  { margin-right: 80px !important; }
	.margin-right-81  { margin-right: 81px !important; }
	.margin-right-82  { margin-right: 82px !important; }
	.margin-right-83  { margin-right: 83px !important; }
	.margin-right-84  { margin-right: 84px !important; }
	.margin-right-85  { margin-right: 85px !important; }
	.margin-right-86  { margin-right: 86px !important; }
	.margin-right-87  { margin-right: 87px !important; }
	.margin-right-88  { margin-right: 88px !important; }
	.margin-right-89  { margin-right: 89px !important; }
	.margin-right-90  { margin-right: 90px !important; }
	.margin-right-91  { margin-right: 91px !important; }
	.margin-right-92  { margin-right: 92px !important; }
	.margin-right-93  { margin-right: 93px !important; }
	.margin-right-94  { margin-right: 94px !important; }
	.margin-right-95  { margin-right: 95px !important; }
	.margin-right-96  { margin-right: 96px !important; }
	.margin-right-97  { margin-right: 97px !important; }
	.margin-right-98  { margin-right: 98px !important; }
	.margin-right-99  { margin-right: 99px !important; }
	.margin-right-100 { margin-right: 100px !important; }


	/* Padding Top */
	.padding-top-0   { padding-top: 0px  !important; }
	.padding-top-1   { padding-top: 1px  !important; }
	.padding-top-2   { padding-top: 2px  !important; }
	.padding-top-3   { padding-top: 3px  !important; }
	.padding-top-4   { padding-top: 4px  !important; }
	.padding-top-5   { padding-top: 5px  !important; }
	.padding-top-6   { padding-top: 6px  !important; }
	.padding-top-7   { padding-top: 7px  !important; }
	.padding-top-8   { padding-top: 8px  !important; }
	.padding-top-9   { padding-top: 9px  !important; }
	.padding-top-10  { padding-top: 10px !important; }
	.padding-top-11  { padding-top: 11px !important; }
	.padding-top-12  { padding-top: 12px !important; }
	.padding-top-13  { padding-top: 13px !important; }
	.padding-top-14  { padding-top: 14px !important; }
	.padding-top-15  { padding-top: 15px !important; }
	.padding-top-16  { padding-top: 16px !important; }
	.padding-top-17  { padding-top: 17px !important; }
	.padding-top-18  { padding-top: 18px !important; }
	.padding-top-19  { padding-top: 19px !important; }
	.padding-top-20  { padding-top: 20px !important; }
	.padding-top-21  { padding-top: 21px !important; }
	.padding-top-22  { padding-top: 22px !important; }
	.padding-top-23  { padding-top: 23px !important; }
	.padding-top-24  { padding-top: 24px !important; }
	.padding-top-25  { padding-top: 25px !important; }
	.padding-top-26  { padding-top: 26px !important; }
	.padding-top-27  { padding-top: 27px !important; }
	.padding-top-28  { padding-top: 28px !important; }
	.padding-top-29  { padding-top: 29px !important; }
	.padding-top-30  { padding-top: 30px !important; }
	.padding-top-31  { padding-top: 31px !important; }
	.padding-top-32  { padding-top: 32px !important; }
	.padding-top-33  { padding-top: 33px !important; }
	.padding-top-34  { padding-top: 34px !important; }
	.padding-top-35  { padding-top: 35px !important; }
	.padding-top-36  { padding-top: 36px !important; }
	.padding-top-37  { padding-top: 37px !important; }
	.padding-top-38  { padding-top: 38px !important; }
	.padding-top-39  { padding-top: 39px !important; }
	.padding-top-40  { padding-top: 40px !important; }
	.padding-top-41  { padding-top: 41px !important; }
	.padding-top-42  { padding-top: 42px !important; }
	.padding-top-43  { padding-top: 43px !important; }
	.padding-top-44  { padding-top: 44px !important; }
	.padding-top-45  { padding-top: 45px !important; }
	.padding-top-46  { padding-top: 46px !important; }
	.padding-top-47  { padding-top: 47px !important; }
	.padding-top-48  { padding-top: 48px !important; }
	.padding-top-49  { padding-top: 49px !important; }
	.padding-top-50  { padding-top: 50px !important; }
	.padding-top-51  { padding-top: 51px !important; }
	.padding-top-52  { padding-top: 52px !important; }
	.padding-top-53  { padding-top: 53px !important; }
	.padding-top-54  { padding-top: 54px !important; }
	.padding-top-55  { padding-top: 55px !important; }
	.padding-top-56  { padding-top: 56px !important; }
	.padding-top-57  { padding-top: 57px !important; }
	.padding-top-58  { padding-top: 58px !important; }
	.padding-top-59  { padding-top: 59px !important; }
	.padding-top-60  { padding-top: 60px !important; }
	.padding-top-61  { padding-top: 61px !important; }
	.padding-top-62  { padding-top: 62px !important; }
	.padding-top-63  { padding-top: 63px !important; }
	.padding-top-64  { padding-top: 64px !important; }
	.padding-top-65  { padding-top: 65px !important; }
	.padding-top-66  { padding-top: 66px !important; }
	.padding-top-67  { padding-top: 67px !important; }
	.padding-top-68  { padding-top: 68px !important; }
	.padding-top-69  { padding-top: 69px !important; }
	.padding-top-70  { padding-top: 70px !important; }
	.padding-top-71  { padding-top: 71px !important; }
	.padding-top-72  { padding-top: 72px !important; }
	.padding-top-73  { padding-top: 73px !important; }
	.padding-top-74  { padding-top: 74px !important; }
	.padding-top-75  { padding-top: 75px !important; }
	.padding-top-76  { padding-top: 76px !important; }
	.padding-top-77  { padding-top: 77px !important; }
	.padding-top-78  { padding-top: 78px !important; }
	.padding-top-79  { padding-top: 79px !important; }
	.padding-top-80  { padding-top: 80px !important; }
	.padding-top-81  { padding-top: 81px !important; }
	.padding-top-82  { padding-top: 82px !important; }
	.padding-top-83  { padding-top: 83px !important; }
	.padding-top-84  { padding-top: 84px !important; }
	.padding-top-85  { padding-top: 85px !important; }
	.padding-top-86  { padding-top: 86px !important; }
	.padding-top-87  { padding-top: 87px !important; }
	.padding-top-88  { padding-top: 88px !important; }
	.padding-top-89  { padding-top: 89px !important; }
	.padding-top-90  { padding-top: 90px !important; }
	.padding-top-91  { padding-top: 91px !important; }
	.padding-top-92  { padding-top: 92px !important; }
	.padding-top-93  { padding-top: 93px !important; }
	.padding-top-94  { padding-top: 94px !important; }
	.padding-top-95  { padding-top: 95px !important; }
	.padding-top-96  { padding-top: 96px !important; }
	.padding-top-97  { padding-top: 97px !important; }
	.padding-top-98  { padding-top: 98px !important; }
	.padding-top-99  { padding-top: 99px !important; }
	.padding-top-100 { padding-top: 100px !important; }

	/* Padding Bottom */
	.padding-bottom-0   { padding-bottom: 0px  !important; }
	.padding-bottom-1   { padding-bottom: 1px  !important; }
	.padding-bottom-2   { padding-bottom: 2px  !important; }
	.padding-bottom-3   { padding-bottom: 3px  !important; }
	.padding-bottom-4   { padding-bottom: 4px  !important; }
	.padding-bottom-5   { padding-bottom: 5px  !important; }
	.padding-bottom-6   { padding-bottom: 6px  !important; }
	.padding-bottom-7   { padding-bottom: 7px  !important; }
	.padding-bottom-8   { padding-bottom: 8px  !important; }
	.padding-bottom-9   { padding-bottom: 9px  !important; }
	.padding-bottom-10  { padding-bottom: 10px !important; }
	.padding-bottom-11  { padding-bottom: 11px !important; }
	.padding-bottom-12  { padding-bottom: 12px !important; }
	.padding-bottom-13  { padding-bottom: 13px !important; }
	.padding-bottom-14  { padding-bottom: 14px !important; }
	.padding-bottom-15  { padding-bottom: 15px !important; }
	.padding-bottom-16  { padding-bottom: 16px !important; }
	.padding-bottom-17  { padding-bottom: 17px !important; }
	.padding-bottom-18  { padding-bottom: 18px !important; }
	.padding-bottom-19  { padding-bottom: 19px !important; }
	.padding-bottom-20  { padding-bottom: 20px !important; }
	.padding-bottom-21  { padding-bottom: 21px !important; }
	.padding-bottom-22  { padding-bottom: 22px !important; }
	.padding-bottom-23  { padding-bottom: 23px !important; }
	.padding-bottom-24  { padding-bottom: 24px !important; }
	.padding-bottom-25  { padding-bottom: 25px !important; }
	.padding-bottom-26  { padding-bottom: 26px !important; }
	.padding-bottom-27  { padding-bottom: 27px !important; }
	.padding-bottom-28  { padding-bottom: 28px !important; }
	.padding-bottom-29  { padding-bottom: 29px !important; }
	.padding-bottom-30  { padding-bottom: 30px !important; }
	.padding-bottom-31  { padding-bottom: 31px !important; }
	.padding-bottom-32  { padding-bottom: 32px !important; }
	.padding-bottom-33  { padding-bottom: 33px !important; }
	.padding-bottom-34  { padding-bottom: 34px !important; }
	.padding-bottom-35  { padding-bottom: 35px !important; }
	.padding-bottom-36  { padding-bottom: 36px !important; }
	.padding-bottom-37  { padding-bottom: 37px !important; }
	.padding-bottom-38  { padding-bottom: 38px !important; }
	.padding-bottom-39  { padding-bottom: 39px !important; }
	.padding-bottom-40  { padding-bottom: 40px !important; }
	.padding-bottom-41  { padding-bottom: 41px !important; }
	.padding-bottom-42  { padding-bottom: 42px !important; }
	.padding-bottom-43  { padding-bottom: 43px !important; }
	.padding-bottom-44  { padding-bottom: 44px !important; }
	.padding-bottom-45  { padding-bottom: 45px !important; }
	.padding-bottom-46  { padding-bottom: 46px !important; }
	.padding-bottom-47  { padding-bottom: 47px !important; }
	.padding-bottom-48  { padding-bottom: 48px !important; }
	.padding-bottom-49  { padding-bottom: 49px !important; }
	.padding-bottom-50  { padding-bottom: 50px !important; }
	.padding-bottom-51  { padding-bottom: 51px !important; }
	.padding-bottom-52  { padding-bottom: 52px !important; }
	.padding-bottom-53  { padding-bottom: 53px !important; }
	.padding-bottom-54  { padding-bottom: 54px !important; }
	.padding-bottom-55  { padding-bottom: 55px !important; }
	.padding-bottom-56  { padding-bottom: 56px !important; }
	.padding-bottom-57  { padding-bottom: 57px !important; }
	.padding-bottom-58  { padding-bottom: 58px !important; }
	.padding-bottom-59  { padding-bottom: 59px !important; }
	.padding-bottom-60  { padding-bottom: 60px !important; }
	.padding-bottom-61  { padding-bottom: 61px !important; }
	.padding-bottom-62  { padding-bottom: 62px !important; }
	.padding-bottom-63  { padding-bottom: 63px !important; }
	.padding-bottom-64  { padding-bottom: 64px !important; }
	.padding-bottom-65  { padding-bottom: 65px !important; }
	.padding-bottom-66  { padding-bottom: 66px !important; }
	.padding-bottom-67  { padding-bottom: 67px !important; }
	.padding-bottom-68  { padding-bottom: 68px !important; }
	.padding-bottom-69  { padding-bottom: 69px !important; }
	.padding-bottom-70  { padding-bottom: 70px !important; }
	.padding-bottom-71  { padding-bottom: 71px !important; }
	.padding-bottom-72  { padding-bottom: 72px !important; }
	.padding-bottom-73  { padding-bottom: 73px !important; }
	.padding-bottom-74  { padding-bottom: 74px !important; }
	.padding-bottom-75  { padding-bottom: 75px !important; }
	.padding-bottom-76  { padding-bottom: 76px !important; }
	.padding-bottom-77  { padding-bottom: 77px !important; }
	.padding-bottom-78  { padding-bottom: 78px !important; }
	.padding-bottom-79  { padding-bottom: 79px !important; }
	.padding-bottom-80  { padding-bottom: 80px !important; }
	.padding-bottom-81  { padding-bottom: 81px !important; }
	.padding-bottom-82  { padding-bottom: 82px !important; }
	.padding-bottom-83  { padding-bottom: 83px !important; }
	.padding-bottom-84  { padding-bottom: 84px !important; }
	.padding-bottom-85  { padding-bottom: 85px !important; }
	.padding-bottom-86  { padding-bottom: 86px !important; }
	.padding-bottom-87  { padding-bottom: 87px !important; }
	.padding-bottom-88  { padding-bottom: 88px !important; }
	.padding-bottom-89  { padding-bottom: 89px !important; }
	.padding-bottom-90  { padding-bottom: 90px !important; }
	.padding-bottom-91  { padding-bottom: 91px !important; }
	.padding-bottom-92  { padding-bottom: 92px !important; }
	.padding-bottom-93  { padding-bottom: 93px !important; }
	.padding-bottom-94  { padding-bottom: 94px !important; }
	.padding-bottom-95  { padding-bottom: 95px !important; }
	.padding-bottom-96  { padding-bottom: 96px !important; }
	.padding-bottom-97  { padding-bottom: 97px !important; }
	.padding-bottom-98  { padding-bottom: 98px !important; }
	.padding-bottom-99  { padding-bottom: 99px !important; }
	.padding-bottom-100 { padding-bottom: 100px !important; }

	/* Padding Right */
	.padding-right-0   { padding-right: 0px  !important; }
	.padding-right-1   { padding-right: 1px  !important; }
	.padding-right-2   { padding-right: 2px  !important; }
	.padding-right-3   { padding-right: 3px  !important; }
	.padding-right-4   { padding-right: 4px  !important; }
	.padding-right-5   { padding-right: 5px  !important; }
	.padding-right-6   { padding-right: 6px  !important; }
	.padding-right-7   { padding-right: 7px  !important; }
	.padding-right-8   { padding-right: 8px  !important; }
	.padding-right-9   { padding-right: 9px  !important; }
	.padding-right-10  { padding-right: 10px !important; }
	.padding-right-11  { padding-right: 11px !important; }
	.padding-right-12  { padding-right: 12px !important; }
	.padding-right-13  { padding-right: 13px !important; }
	.padding-right-14  { padding-right: 14px !important; }
	.padding-right-15  { padding-right: 15px !important; }
	.padding-right-16  { padding-right: 16px !important; }
	.padding-right-17  { padding-right: 17px !important; }
	.padding-right-18  { padding-right: 18px !important; }
	.padding-right-19  { padding-right: 19px !important; }
	.padding-right-20  { padding-right: 20px !important; }
	.padding-right-21  { padding-right: 21px !important; }
	.padding-right-22  { padding-right: 22px !important; }
	.padding-right-23  { padding-right: 23px !important; }
	.padding-right-24  { padding-right: 24px !important; }
	.padding-right-25  { padding-right: 25px !important; }
	.padding-right-26  { padding-right: 26px !important; }
	.padding-right-27  { padding-right: 27px !important; }
	.padding-right-28  { padding-right: 28px !important; }
	.padding-right-29  { padding-right: 29px !important; }
	.padding-right-30  { padding-right: 30px !important; }
	.padding-right-31  { padding-right: 31px !important; }
	.padding-right-32  { padding-right: 32px !important; }
	.padding-right-33  { padding-right: 33px !important; }
	.padding-right-34  { padding-right: 34px !important; }
	.padding-right-35  { padding-right: 35px !important; }
	.padding-right-36  { padding-right: 36px !important; }
	.padding-right-37  { padding-right: 37px !important; }
	.padding-right-38  { padding-right: 38px !important; }
	.padding-right-39  { padding-right: 39px !important; }
	.padding-right-40  { padding-right: 40px !important; }
	.padding-right-41  { padding-right: 41px !important; }
	.padding-right-42  { padding-right: 42px !important; }
	.padding-right-43  { padding-right: 43px !important; }
	.padding-right-44  { padding-right: 44px !important; }
	.padding-right-45  { padding-right: 45px !important; }
	.padding-right-46  { padding-right: 46px !important; }
	.padding-right-47  { padding-right: 47px !important; }
	.padding-right-48  { padding-right: 48px !important; }
	.padding-right-49  { padding-right: 49px !important; }
	.padding-right-50  { padding-right: 50px !important; }
	.padding-right-51  { padding-right: 51px !important; }
	.padding-right-52  { padding-right: 52px !important; }
	.padding-right-53  { padding-right: 53px !important; }
	.padding-right-54  { padding-right: 54px !important; }
	.padding-right-55  { padding-right: 55px !important; }
	.padding-right-56  { padding-right: 56px !important; }
	.padding-right-57  { padding-right: 57px !important; }
	.padding-right-58  { padding-right: 58px !important; }
	.padding-right-59  { padding-right: 59px !important; }
	.padding-right-60  { padding-right: 60px !important; }
	.padding-right-61  { padding-right: 61px !important; }
	.padding-right-62  { padding-right: 62px !important; }
	.padding-right-63  { padding-right: 63px !important; }
	.padding-right-64  { padding-right: 64px !important; }
	.padding-right-65  { padding-right: 65px !important; }
	.padding-right-66  { padding-right: 66px !important; }
	.padding-right-67  { padding-right: 67px !important; }
	.padding-right-68  { padding-right: 68px !important; }
	.padding-right-69  { padding-right: 69px !important; }
	.padding-right-70  { padding-right: 70px !important; }
	.padding-right-71  { padding-right: 71px !important; }
	.padding-right-72  { padding-right: 72px !important; }
	.padding-right-73  { padding-right: 73px !important; }
	.padding-right-74  { padding-right: 74px !important; }
	.padding-right-75  { padding-right: 75px !important; }
	.padding-right-76  { padding-right: 76px !important; }
	.padding-right-77  { padding-right: 77px !important; }
	.padding-right-78  { padding-right: 78px !important; }
	.padding-right-79  { padding-right: 79px !important; }
	.padding-right-80  { padding-right: 80px !important; }
	.padding-right-81  { padding-right: 81px !important; }
	.padding-right-82  { padding-right: 82px !important; }
	.padding-right-83  { padding-right: 83px !important; }
	.padding-right-84  { padding-right: 84px !important; }
	.padding-right-85  { padding-right: 85px !important; }
	.padding-right-86  { padding-right: 86px !important; }
	.padding-right-87  { padding-right: 87px !important; }
	.padding-right-88  { padding-right: 88px !important; }
	.padding-right-89  { padding-right: 89px !important; }
	.padding-right-90  { padding-right: 90px !important; }
	.padding-right-91  { padding-right: 91px !important; }
	.padding-right-92  { padding-right: 92px !important; }
	.padding-right-93  { padding-right: 93px !important; }
	.padding-right-94  { padding-right: 94px !important; }
	.padding-right-95  { padding-right: 95px !important; }
	.padding-right-96  { padding-right: 96px !important; }
	.padding-right-97  { padding-right: 97px !important; }
	.padding-right-98  { padding-right: 98px !important; }
	.padding-right-99  { padding-right: 99px !important; }
	.padding-right-100 { padding-right: 100px !important; }

	/* Padding Left */
	.padding-left-0   { padding-left: 0px  !important; }
	.padding-left-1   { padding-left: 1px  !important; }
	.padding-left-2   { padding-left: 2px  !important; }
	.padding-left-3   { padding-left: 3px  !important; }
	.padding-left-4   { padding-left: 4px  !important; }
	.padding-left-5   { padding-left: 5px  !important; }
	.padding-left-6   { padding-left: 6px  !important; }
	.padding-left-7   { padding-left: 7px  !important; }
	.padding-left-8   { padding-left: 8px  !important; }
	.padding-left-9   { padding-left: 9px  !important; }
	.padding-left-10  { padding-left: 10px !important; }
	.padding-left-11  { padding-left: 11px !important; }
	.padding-left-12  { padding-left: 12px !important; }
	.padding-left-13  { padding-left: 13px !important; }
	.padding-left-14  { padding-left: 14px !important; }
	.padding-left-15  { padding-left: 15px !important; }
	.padding-left-16  { padding-left: 16px !important; }
	.padding-left-17  { padding-left: 17px !important; }
	.padding-left-18  { padding-left: 18px !important; }
	.padding-left-19  { padding-left: 19px !important; }
	.padding-left-20  { padding-left: 20px !important; }
	.padding-left-21  { padding-left: 21px !important; }
	.padding-left-22  { padding-left: 22px !important; }
	.padding-left-23  { padding-left: 23px !important; }
	.padding-left-24  { padding-left: 24px !important; }
	.padding-left-25  { padding-left: 25px !important; }
	.padding-left-26  { padding-left: 26px !important; }
	.padding-left-27  { padding-left: 27px !important; }
	.padding-left-28  { padding-left: 28px !important; }
	.padding-left-29  { padding-left: 29px !important; }
	.padding-left-30  { padding-left: 30px !important; }
	.padding-left-31  { padding-left: 31px !important; }
	.padding-left-32  { padding-left: 32px !important; }
	.padding-left-33  { padding-left: 33px !important; }
	.padding-left-34  { padding-left: 34px !important; }
	.padding-left-35  { padding-left: 35px !important; }
	.padding-left-36  { padding-left: 36px !important; }
	.padding-left-37  { padding-left: 37px !important; }
	.padding-left-38  { padding-left: 38px !important; }
	.padding-left-39  { padding-left: 39px !important; }
	.padding-left-40  { padding-left: 40px !important; }
	.padding-left-41  { padding-left: 41px !important; }
	.padding-left-42  { padding-left: 42px !important; }
	.padding-left-43  { padding-left: 43px !important; }
	.padding-left-44  { padding-left: 44px !important; }
	.padding-left-45  { padding-left: 45px !important; }
	.padding-left-46  { padding-left: 46px !important; }
	.padding-left-47  { padding-left: 47px !important; }
	.padding-left-48  { padding-left: 48px !important; }
	.padding-left-49  { padding-left: 49px !important; }
	.padding-left-50  { padding-left: 50px !important; }
	.padding-left-51  { padding-left: 51px !important; }
	.padding-left-52  { padding-left: 52px !important; }
	.padding-left-53  { padding-left: 53px !important; }
	.padding-left-54  { padding-left: 54px !important; }
	.padding-left-55  { padding-left: 55px !important; }
	.padding-left-56  { padding-left: 56px !important; }
	.padding-left-57  { padding-left: 57px !important; }
	.padding-left-58  { padding-left: 58px !important; }
	.padding-left-59  { padding-left: 59px !important; }
	.padding-left-60  { padding-left: 60px !important; }
	.padding-left-61  { padding-left: 61px !important; }
	.padding-left-62  { padding-left: 62px !important; }
	.padding-left-63  { padding-left: 63px !important; }
	.padding-left-64  { padding-left: 64px !important; }
	.padding-left-65  { padding-left: 65px !important; }
	.padding-left-66  { padding-left: 66px !important; }
	.padding-left-67  { padding-left: 67px !important; }
	.padding-left-68  { padding-left: 68px !important; }
	.padding-left-69  { padding-left: 69px !important; }
	.padding-left-70  { padding-left: 70px !important; }
	.padding-left-71  { padding-left: 71px !important; }
	.padding-left-72  { padding-left: 72px !important; }
	.padding-left-73  { padding-left: 73px !important; }
	.padding-left-74  { padding-left: 74px !important; }
	.padding-left-75  { padding-left: 75px !important; }
	.padding-left-76  { padding-left: 76px !important; }
	.padding-left-77  { padding-left: 77px !important; }
	.padding-left-78  { padding-left: 78px !important; }
	.padding-left-79  { padding-left: 79px !important; }
	.padding-left-80  { padding-left: 80px !important; }
	.padding-left-81  { padding-left: 81px !important; }
	.padding-left-82  { padding-left: 82px !important; }
	.padding-left-83  { padding-left: 83px !important; }
	.padding-left-84  { padding-left: 84px !important; }
	.padding-left-85  { padding-left: 85px !important; }
	.padding-left-86  { padding-left: 86px !important; }
	.padding-left-87  { padding-left: 87px !important; }
	.padding-left-88  { padding-left: 88px !important; }
	.padding-left-89  { padding-left: 89px !important; }
	.padding-left-90  { padding-left: 90px !important; }
	.padding-left-91  { padding-left: 91px !important; }
	.padding-left-92  { padding-left: 92px !important; }
	.padding-left-93  { padding-left: 93px !important; }
	.padding-left-94  { padding-left: 94px !important; }
	.padding-left-95  { padding-left: 95px !important; }
	.padding-left-96  { padding-left: 96px !important; }
	.padding-left-97  { padding-left: 97px !important; }
	.padding-left-98  { padding-left: 98px !important; }
	.padding-left-99  { padding-left: 99px !important; }
	.padding-left-100 { padding-left: 100px !important; }