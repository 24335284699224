
/* ---------------------------------- */
/* On/Off Switch
------------------------------------- */

.switches-list .switch-container {
  width: 100%;
  &:last-child label {
    margin-bottom: 0;
  }
}

.switch-container {
  display: inline-block;
  cursor: pointer;
}

label.switch {
  position: relative;
}

.switches-list .switch-container label {
  cursor: pointer;
  position: relative;
  padding-left: 45px;
  line-height: $body-sub-line-height;
}

.switch-button {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 50px;
  transition: 0.4s;
  margin: 0 7px 0 0;
  //top: 4px;
  //position: absolute;
  top: 2px;
  left: 0;
}

.notify-box {
  .switch-button {
    top: 0;
  }
  .switch-container {
    top: 1px;
    position: relative;
  }
}

.switch {
  span.switch-text {
    display: block;
    padding-left: 48px;
    font-size: $body-font-size;
    line-height: 22px;
    color: $body-font-color;
  }
  input {
    display: none;
  }
  &.interactive-effect {
    input:checked + .switch-button:before {
      animation: switch-shadow-color 0.4s;
    }
    .switch-button:before {
      animation: switch-shadow 0.4s;
    }
  }
}

@keyframes switch-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }

  100% {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0);
  }
}


@keyframes switch-shadow-color {
  0% {
    box-shadow: 0 0 0 0 rgba($primary-color, 0.6);
  }

  100% {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0);
  }
}


.switch-button:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  bottom: 2px;
  left: 2px;
  background-color: white;
  transition: 0.4s, box-shadow 0.3s;
  border-radius: 50px;
}

input:checked + .switch-button {
  background-color: $primary-color;
  &:before {
    transform: translateX(14px);
  }
}