/*Bigger CTA Style Button*/
.btn-cta {
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 15px 20px;
    font-size: 0.8rem;
    font-weight: 600;
}
/*Alert styles*/
.event-type {
    border: 3px solid #e0e6ed;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.2s;
    transition-delay: 0.3s;
}
.event-type .event-indicator{
    transition: all cubic-bezier(0,.89,.44,1) 0.2s;
    transform: scale(0);
    opacity: 0;
    transition-delay: 0.5s;
}
.show .event-type .event-indicator{
    transform: scale(1);
    opacity: 1;
}
.show .event-type{
    border-color: #e0e6ed;
    background-color: #e0e6ed ;
}
.show .event-type.success{
    border-color: #00CC99;
    background-color: #00CC99 ;
}

.show .event-type.error{
    border-color: #f2545b;
    background-color: #f2545b ;
}

.show .event-type.warning{
    border-color: #f7bc06;
    background-color: #f7bc06 ;
}

.show .event-type.info{
    border-color: #19b5fe;
    background-color: #19b5fe ;
}

