.gateway-logo {
  height: 40px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 115px auto;
}

.gl-help-tip {
  background-color: #d0ead0;
  border-radius: 3px;
  border: 1px solid #686868;
  padding: 3px 5px;

  h4 {
    padding: 3px 6px;
    border-radius: 5px;
    display: inline-block;
  }

  p {
    margin: 0;
    display: inline;
  }
}

.add-rule-table,
.edit-rule-table {
  tbody {
    & > tr th .label-if {
      display: none;
    }

    & > tr th .label-and {
      display: inline-block;
    }

    & > tr:first-child th .label-if {
      display: inline-block;
    }

    & > tr:first-child th .label-and {
      display: none;
    }
  }

  .label {
    padding: 6px 10px;
    border-radius: 5px;
  }
}

#default-custom-rule-row {
  display: none;
}

.add-rule-table {
  tbody {
    textarea {
      min-height: auto;
      height: 100px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    select[multiple] {
      height: 100px;
    }

    tr {
      background-color: #fff;

      &:nth-child(2n+2) {
        background-color: #f3f3f3;
      }
    }
  }
}

#payment-rule-table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
.paymentmethod-box {
  .option-box-grid {
    label {
      &:after {
        display: none;
      }
    }
  }
}
#methodSettingsModal {

  #paymentMethodImage {
    margin-bottom: 0;
  }

  #methodSettingsModalLabel {
    line-height: 70px;
    font-size: 36px;
  }

  .modal-header .close {
    font-size: 36px;
  }

  .gateway-settings {
    padding-top: 50px !important;

    &:before {
      content: 'API Ayarları';
      position: absolute;
      top: 10px;
      width: 300px;
      text-align: center;
      left: 50%;
      margin-left: -150px;
      background: #333;
      color: #fff;
      border-radius: 4px;
    }
  }

  .label-col {
    background-color: #f1f1f1;
    border-right: 1px solid #CCC;

    label {
      margin-bottom: 0;
      line-height: 40px;
    }

    i {
      cursor: pointer;
    }
  }

  .element-col {
    padding: 5px;
  }

  .field-row {
    line-height: 50px;
    border-bottom: 1px solid #e5e5e5;
  }

  select, input {
    height: 32px;
    line-height: 32px;
    margin-bottom: 0;
    padding: 0 5px;
  }

  .checkbox {
    float: left;
    margin-top: 5px;
  }

  .installment-settings {
    padding-top: 50px !important;

    .field-row {
      //width: 46%;
      //display: flex;
      //float: left;
      //margin: 0 2%;
      .label-col {
        text-align: right;
      }
    }

    &:before {
      content: 'Taksit Komisyon Oranları';
      position: absolute;
      top: 10px;
      width: 300px;
      text-align: center;
      left: 50%;
      margin-left: -150px;
      background: #333;
      color: #fff;
      border-radius: 4px;
    }

  }

}

.new-payment-method-box {
  &:not(.selected) {
    transition: all .2s;

    &:hover {
      opacity: .5;
    }
  }
}

.active-payment-methods {
  .method-image {
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
}
.installment-table {
  thead {
    margin-bottom: 10px;

    th {
      padding: 5px 5px 5px 5px !important;
      margin-bottom: 10px;
      line-height: 32px;
    }
  }

  input[type="text"], input[type="number"] {
    width: 100px !important;
    height: 32px !important;
    display: inline-block !important;
    float: none;
    margin-bottom: 0;
  }

  tr {
    th, td {
      padding: 5px 0;
    }
  }

  .checkbox label span.checkbox-icon, .checkbox label span.checkbox-indeterminate {
    top: 2px;
  }
}
.payment-method-header {
  font-size: 18px;
}
.gateway-set-default {
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    background-color: rgba($success, 0.5);
  }
}