a{
  color: $primary-color;
}
.table th, .table td{
  padding: 0.5rem 0.75rem;
}
.bg-paymendo{
  background-color: $primary-color !important;
}

.user-select-none{
  user-select: none;
}
.CalendarDay__today{
  background-color: rgba($primary-color, 0.05) !important;
}
