/*
Theming
*/
/* -------------------------------------------------------------- */
/* Default Colors
----------------------------------------------------------------- */
$primary-color: 							#416f8e;
$body-font-color:							#666;
$body-heading-color:						#333;


/* -------------------------------------------------------------- */
/* Typography
----------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&subset=latin-ext&display=swap');

$primary-font: 								"Roboto", sans-serif;
$body-font-size: 							14px;
$body-line-height: 							1.5;
$body-sub-line-height: 						26px;

$homepage-heading-font-size: 				26px;
$single-page-heading-font-size: 			20px;
$listing-heading-font-size: 				18px;

$global-border-radius:						4px;


/* -------------------------------------------------------------- */
/* Header Settings for Desktop Devices
----------------------------------------------------------------- */
$header-height: 							60px;
$navigation-top-offset: 					0;
$logo-height: 								45px;
