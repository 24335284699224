@import "../vendor/bs-4/variables";
@import "../vendor/bs-4/functions";

.DateInput .DateInput_input {
  font-size: 14px;
  font-weight: 400;
}

.DateInput_input {
  margin-bottom: 0 !important;
  background: transparent !important;
  border: none !important;
  box-shadow: 0 0 0 !important;
}

.DateRangePickerInput_arrow {
  margin-left: 5px;
  margin-right: 5px;
}

.DateRangePickerInput_clearDates {
  width: 25px;
  height: 25px;
  padding: 0 !important;
}

.DateInput {
  width: 90px !important;
}

.invoice-client-detail {
  input.datepicker {
    background: none;
    width: 110px;
    float: right;
    padding: 0;
    line-height: 30px;
    height: auto;
    box-shadow: 0 0 0;
    border: 0 none;
    border-radius: 0;
    margin: 0;
    text-align: right;
  }
}

/*customizations*/
.DayPicker {
  visibility: unset;
}

.DayPicker_transitionContainer:before {
  content: '';
  display: block;
  padding-top: 100%; /* ratio of 1:1*/
}

.DayPicker_transitionContainer .CalendarMonthGrid__horizontal {
  top: 0;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #347598 !important;
  border: 1px double #acd4ef !important;
}

.CalendarDay__selected_span:hover,
.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background: #347598bf !important;
  border: 1px double #347598bf !important;
  color: #fff;
}

.CalendarDay__default:hover {
  background: #bcdbef !important;
  border: 1px solid #bcdbef !important;
  color: #777 !important;
}
.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background: rgba(188, 219, 239, 0.6) !important;
  border: 1px solid rgba(188, 219, 239, 0.6) !important;
  color: #777 !important;
}