.app-notification {
  position: relative;
  width: 300px;
  padding: 0.5rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 999999;

  .dropdown-item {
    span.title {
      font-size: 14px;
    }
  }

  .delete-single {
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
  }

  &.top {
    &::before {
      content: "";
      position: absolute;
      top: -0.4rem;
      right: 8.3rem;
      border-left: 2rem solid transparent;
      border-right: 2rem solid transparent;
      border-bottom: 1.5rem solid #fff;
    }
  }

  &.left {
    top: -40px !important;
    left: 40px !important;

    &::before {
      //-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      z-index: -1;
      content: " ";
      display: inline-block;
      height: 12px;
      width: 12px;
      transform: rotate(-45deg);
      transform-origin: center center;
      background-color: #fff;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      border-left: 1px solid rgba(0, 0, 0, 0.15);
      margin-left: -3px;
      margin-right: -3px;
      margin-bottom: -3px;
      top: 20px;
      left: -4px;
      position: absolute;
    }

    &::after {
      content: " ";
      border-left: 1px solid #fff;
      top: 18px;
      position: absolute;
      left: -1px;
      height: 16px;
    }
  }

  &.top-right {
    &::before {
      left: auto;
      right: 0em;
    }
  }
}