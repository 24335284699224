.multimedia-item-single {
  position: relative;

  .media-actions {
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in-out;
    background: rgba(255, 255, 255, .75);
    position: absolute;
    left: 0;
    top: 0;
    vertical-align: bottom;
    width: 100%;
    padding-top: 50px;
    height: 100%;
  }

  &:hover {
    .media-actions {
      visibility: visible;
      opacity: 1;
    }
  }
}