.page-not-found {
  width: 750px;
  svg {
    position: absolute;
    top: 20%;
    left: 50%;
    margin-top: px;
    margin-left: -400px;
  }

  .message-box {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: 50px;
    font-family: Roboto;
    font-weight: 300;
  }

  .message-box h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
  }

  .buttons-con .action-link-wrap {
    margin-top: 40px;
  }

  .buttons-con .action-link-wrap a {
    background: #68c950;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
  }

  .buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
  }

  #Polygon-1, #Polygon-2, #Polygon-3, #Polygon-4, #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }

  #Polygon-2 {
    animation-delay: .2s;
  }

  #Polygon-3 {
    animation-delay: .4s;
  }

  #Polygon-4 {
    animation-delay: .6s;
  }

  #Polygon-5 {
    animation-delay: .8s;
  }

  @keyframes float {
    100% {
      transform: translateY(20px);
    }
  }
  @media (max-width: 450px) {
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -250px;
      margin-left: -190px;
    }
    .message-box {
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -190px;
      text-align: center;
    }
  }
}