.mailer-filter-table {
  th {
    text-align: right;
  }

  th, td {
    border-top: 0 none;
    padding: 0.75rem 1rem;
  }
}
.result-line {
  font-size: 14px;
  line-height: 24px;

  &:nth-child(2n+1) {
    background-color: rgba(0, 0, 0, .05);
  }

  &:hover {
    background-color: #e0e0e0;
  }
}