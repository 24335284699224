.last-login-row {
  transition: background-color .2s;

  svg {
    transition: background-color .2s ease-in-out;
  }

  &:hover {
    background-color: #f9f9f9;

    svg {
      background-color: $secondary;
    }
  }
}
