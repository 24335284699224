//.select2{
.select2-container--default .select2-selection--single {
  height: 48px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  height: 32px;

  &#select2-new-payment-method-container {
    height: 68px;
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 45px;
  right: 5px;
}

//}
.modal {
  .select2 {
    width: 100% !important;
  }

  .input-with-icon-left {
    .select2 {
      width: calc(100% - 48px) !important;
      float: right;

      .select2-selection--single {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }
    }

    &.gl-select2-row {
      height: 48px;
      margin-bottom: 16px;
    }
  }
}

.select2-container .select2-selection--single[aria-labelledby='select2-new-payment-method-container'] {
  height: 70px;
}

.gl-select2 {
  visibility: hidden;

  &.visible {
    visibility: visible;
  }

  .select2-selection__clear {
    margin-right: 10px;
  }
}

.width-auto {
  .select2-container {
    width: auto !important;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 20px;
    padding-right: 30px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    opacity: .5;
  }
}

.no-search-select {
  .select2-search {
    display: none
  }
}

.clear-select {
  .select2-container--default .select2-selection--single {
    background-color: transparent;
    box-shadow: 0 0 0;
  }
}

.item-selected-text {
  position: fixed;
  line-height: 38px;
}

div[class$="ValueContainer"][class^=" css"], div[class$="ValueContainer"][class^="css"]{
  height: 38px;
  line-height: 85px;
  &>div[class$="Input"]{
    line-height: 34px !important;
    height: 34px;
    margin-top: 0;
    padding-top: 0;
  }
}