.settings-page {
  .settings-table {
    .setting-group-head {
      padding: 5px 10px !important;
      width: auto;
      display: inline-block;
      margin-top: 20px;
      float: none;
      //background-color: #c0dceb;
      border-radius: 4px 4px 0 0;

      h3 {

      }
    }

    .settings-input-td {
      border: 1px solid #EEE;
      padding: 0;
      border-radius: 4px;
      overflow: hidden;

      table {
        margin-bottom: 0;
      }
    }

    tbody {
      tr {
        table {
          tr {
            td, th {
              input[type="text"], input[type="password"], input[type="email"], input[type="number"] {
                height: 32px;
                line-height: 32px;
                box-shadow: 0 0 0;
                margin-bottom: 0;
                border: 1px solid #e5e5e5;
              }

              .checkbox {
                margin-bottom: 10px;
              }
            }

            th {
              text-align: right;
              background-color: #f7f7f7;
              border-right: 1px solid #f5f5f5;
            }
          }
        }
      }
    }
  }

  .nav-tabs {
    display: block;
    text-align: center;
    background-color: #355576;
    border-radius: 10px 10px 0 0;

    .nav-link {
      display: inline-block;
      border: 0;
      border-radius: 0;
      margin: 10px 0;
      background-color: rgba(249, 249, 249, .5);
      color: #fff;
      float: none;

      &.active, &.show .nav-link {
        background-color: lightslategray;
        color: #fff;

        &:hover {
          background-color: lightslategray;
        }
      }

      &:hover {
        background-color: rgba(249, 249, 249, .6);
        color: #fff;
      }
    }
  }
}
.system-settings-page{
  th{
    vertical-align: top;
    padding-top: 5px;
  }
}