@import "custom/credit_card";
@import "custom/table_style";
@import "custom/others";
@import "custom/admin_client";
@import "custom/gateways";
@import "custom/select2";
@import "custom/settings";
@import "custom/dropzone";
@import "custom/addresses";
@import "custom/media_item";
@import "custom/mailer";
@import "custom/notification";
@import "custom/login";
@import "custom/admin_templates";
@import "custom/only_mobile";
@import "custom/admin_orders";
@import "custom/admin_dashboard";
@import "custom/date_picker";
@import "custom/client_dashboard";
@import "custom/not_found";
@import "custom/forms";
@media screen and (max-width: 600px) {
  @import "custom/mobile_edits";
}