div.dataTables_wrapper thead tr > th:first-child,
div.dataTables_wrapper tbody tr > th:first-child,
div.dataTables_wrapper tbody tr > td:first-child {
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-align: center;
}

#cp-billing-information-table {
  tr {
    td, th {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}

.table-page {
  float: left;
  display: block;
  //position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 280px);
  height: auto;
  margin-left: 280px;
  @media screen and (max-width: 600px) {
    margin-left: 0;
    width: 100%;
  }

  .pageRight-Fixed {
    position: fixed !important;
    right: 41px !important;
    width: calc(100% - 325px) !important;
    @media screen and (max-width: 600px) {
      width: 100% !important;
      position: relative !important;
      right: 0 !important;
    }
  }

  @media screen and (min-width: 720px) {
    .dataTables_scrollBody {
      //@extend .pageRight-Fixed;
      top: 187px;
      //border-left: 1px solid #d3d3d3;
      //border-right: 1px solid #d3d3d3;
      overflow: hidden;
      border-bottom: 0;
      display: table;
      table-layout: fixed;
      border-collapse: collapse;
      width: calc(100% - 66px) !important;
      margin-left: 25px;
      text-align: left;
      background-color: #FDFDFD;
      vertical-align: middle;
      box-sizing: content-box;
      padding-bottom: 30px;
      border-bottom: 0 !important;
    }
  }

  .dataTables_scrollHead {
    @extend .pageRight-Fixed;
    @media screen and (min-width: 720px) {
      top: 220px;
      border: 0px solid #d3d3d3 !important;
      border-bottom-width: 1px !important;
      //border-radius: 4px 4px 0 0 !important;
      height: 50px;
      z-index: 10;
      background-color: #fff;
    }
    width: calc(100% - 346px) !important;
  }

  @media screen and (min-width: 720px) {
    div.dataTables_wrapper tbody::before {
      display: none;
    }
  }
}

.table-page {
  .table-filter-box {
    top: 80px;
    padding-top: 20px;
    display: block;
    float: left;
    z-index: 2;
    background-color: #fff;
    height: 140px;
    right: 57px !important;
    width: calc(100% - 346px) !important;
    @media screen and (max-width: 600px) {
      height: auto;
      right: 0;
      left: 0;
      top: 0 !important;
      width: 100% !important;
    }
  }
}

.gl-table-footer-wrap {
  @extend .pageRight-Fixed;
  bottom: 0;
  background-color: #fff;
}

.gl-table-footer {
  height: 56px;
  background: #fff;
  border: 0 solid #d3d3d3;
  border-top-width: 1px;
  border-radius: 0 0 4px 4px;
  padding: 0 5px 5px 5px;
}

.gl-table-container {
}

div.dataTables_wrapper {
  width: 100%;

  .no-sort::after {
    display: none !important;
  }

  .no-sort {
    pointer-events: none !important;
    cursor: default !important;
  }

  table.dataTable thead .sorting::before, table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::before, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::before, table.dataTable thead .sorting_desc::after, table.dataTable thead .sorting_asc_disabled::before, table.dataTable thead .sorting_asc_disabled::after, table.dataTable thead .sorting_desc_disabled::before, table.dataTable thead .sorting_desc_disabled::after {
    bottom: 0.2em;
  }

  table.dataTable thead {
    th, td {
      border-bottom: 0 none;
      padding-left: 10px;
      //padding-top: 0;
      line-height: 40px;
    }

    tr {
      background-color: transparent;
    }
  }

  tbody {
    &:before {
      content: "@";
      display: block;
      line-height: 20px;
      text-indent: -99999px;
    }

    tr {
      th, td {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

  }

  .checkbox {
    label {
      margin-top: -15px;
    }
  }

  div.dataTables_paginate {
    float: left;
    padding-left: 0;
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;

    ul.pagination {
      padding-left: 0;
      margin-left: 0;
    }

    .paginate_button {
      padding: 0;
      text-align: center;
      width: 36px;
      height: 36px;
      line-height: 36px;
      min-width: auto;
      background-color: #f2f2f2;
      margin: 0;
      border: 0 none;
      box-shadow: 0 0 0;
      border-radius: 0 0 0 0;

      &:hover, &:focus {
        background: #e2e2e2;
        border: 0 none;
        box-shadow: 0 0 0;
      }

      &.active {
        background-color: #d2d2d2;
        font-weight: bold;
        box-shadow: 0 0 0;
      }
    }
  }

  .dataTables_info {
    float: left;
    padding-top: 0 !important;
    margin-top: -5px !important;
    color: #666;
  }

  .dataTables_length {
    float: right;
    padding-right: 5px;
    padding-top: 3px;

    select {
      border: 0;
      background-color: transparent;
      box-shadow: 0 0 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */
      background-image: url("../../images/down-arrow.svg");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: 27px center;
      padding: 0 10px 0 0;
      background-color: #eee;
      height: 34px;
      line-height: 30px;
      border-radius: 4px;
      border: 1px solid #ccc;
      margin-top: 7px;
      margin-bottom: 0;
    }
  }
}

.alone-table {
  div.dataTables_wrapper {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px #CCC;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 20px;

    .dataTables_info {
      margin-right: 20px !important;
    }

    div.dataTables_paginate {
      margin-left: 20px !important;
    }
  }

  table {
    tr {
      td, th {
        i {
          &:before {
            font-size: 12px;
          }
        }
      }
    }
  }
}

div.dataTables_wrapper {
  div.dataTables_processing {
    left: 0;
    margin-left: 0;
    top: 0;
    margin-top: 0;
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    background-color: rgba(255, 255, 255, .85);
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 0;
  }
}

.gl-datatable {
  font-size: 16px;

  .price-view {
    font-size: 18px;
  }
  tr{
    &>td:first-child,&>th:first-child{
      padding-left: 20px;
    }
  }
}

.table-row {
  &:hover {
    td, th {
      background-color: #ddd;
    }
  }
}

.table-style-1 {
  font-size: 16px;
  line-height: 40px;

  & > div > table {
    border-collapse: separate;
    border-spacing: 0 1em;
  }

  .gl-table-header {
    th {
      border-bottom: 0 none;
    }
  }

  .gl-table-body {
    tr {
      margin-bottom: 15px;
      border-radius: 10px;
      border: 1px solid #CCC;
      background: #fdfdfd !important;

      td, th {
        border-top: 0 none;
      }

      &:nth-child(2n+1) {
        background-color: #f7f7f7 !important;
      }
    }
  }

  .table-buttons {
    margin-top: 7px;
  }

  .order-status-view {
    display: grid;
    margin-top: 3px;
  }

  .checkbox label {
    margin-bottom: 7px !important;
  }
}

.gl-table-header {
  tr {
    td, th {
      border-top: 0;
    }
  }
}

.table-page-wrapper {
  .gl-table-footer {
    width: calc(100% - 280px);
    //margin-left: 21px;
  }
}

.table-page-wrapper {
  .filter-180 {
    .page-filter-bar {
      &:before {
        height: 178px;
      }

      height: 180px;
    }

    .gl-table-header {
      top: 180px;
    }

    .gl-table-body {
      top: 219px;
    }
  }

  .filter-160 {
    .page-filter-bar {
      &:before {
        height: 158px;
      }

      height: 160px;
    }

    .gl-table-header {
      top: 160px;
    }

    .gl-table-body {
      top: 199px;
    }
  }
}

.table-no-space {
  tr {
    td, th {
      padding: 0;
    }
  }
}

.stick-table-footer {
  .gl-table-footer {
    margin: 0;
    width: 100%;
  }
}

.page-filter-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 280px;
  height: 150px;
  z-index: 99999;
  background-color: #fff;
  background-image: url("../../images/title-bg.png");
  background-size: auto 100%;
  background-position: center right;
  background-repeat: no-repeat;
  width: calc(100% - 280px);
  padding: 0;
  border-bottom: 2px solid $primary-color;

  &.h100px {
    height: 100px;
  }

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(255, 255, 255, .75);
    z-index: 0;
    height: 100%;
  }

  .title-box {
    height: 50px;
  }

  .search-box {
    height: 85px;
    margin: 5px;
    border-radius: 4px;
  }

  small {
    line-height: 14px;
    font-style: italic;
    margin-top: 5px;
  }
}

.gl-datatable-pagination-select-div {
  input, input:focus, input:active {
    box-shadow: 0 0 0;
    line-height: 40px;
    margin-top: -5px;
  }
}

.table-page-wrapper {
  //tbody{
  //  overflow: scroll;
  //}
  .container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  .dashboard-content-inner {
    height: 100%;

    & > div {
      height: 100%;

      .container {
        height: 100%;

        & > div {
          height: 100%;

          table {
            height: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .gl-table-footer {
    position: fixed;
    left: 280px;
    right: 15px;
    bottom: 0;
    width: auto;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 20px !important;
    padding-top: 7px !important;
  }

  .gl-table-body {
    position: absolute;
    left: 0;
    right: 0;
    top: 190px;
    width: 100%;

    tr {
      display: inline-flex;
      width: 100%;
    }
  }

  .gl-table-header {
    position: fixed;
    background: #fff;
    z-index: 10000;
    right: 15px;
    left: 280px;
    top: 150px;
    width: calc(100% - 280px);

    tr {
      display: inline-flex;
      width: 100%;
    }
  }

  #header-container {
    position: fixed;
  }
}


div.dataTables_scrollBody {
  table.gl-datatable {
    margin-bottom: 85.5px !important;
  }
}

.hide-empty {
  &:empty {
    visibility: hidden;
  }
}


.clients-table-style {
  tr.client-disabled {
    background-color: rgba(255, 0, 0, .15) !important;
  }
}

.client-header-title {
  line-height: 45px;
}

.table-refund-bg {
  background-color: #ffd0d0 !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

.table-page-loading-div {
  position: absolute;
  top: 50%;
  margin-top: -10px;
}