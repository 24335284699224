/* ---------------------------------- */
/* Forms Reset & Styles
------------------------------------- */

$body-input-font-color: #808080;

select {
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  outline: none;
  font-size: $body-font-size;
  color: $body-input-font-color;
  margin: 0 0 16px 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  font-weight: 500;
  opacity: 1;
  border-radius: $global-border-radius;
  border: none;
  //box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
  padding: 15px 18px;
  cursor: pointer;
}

input {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;

  &:focus, &[type="text"]:focus, &[type="password"]:focus, &[type="email"]:focus, &[type="number"]:focus {
    color: $body-input-font-color;
    transition: box-shadow 0.2s !important;
    opacity: 1;
    //border: none;
    //box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
  }
}

textarea:focus {
  color: $body-input-font-color;
  transition: box-shadow 0.2s !important;
  opacity: 1;
  //border: none;
  //box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

input {
  &:-webkit-autofill {
    &:focus {
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
      -webkit-text-fill-color: $body-input-font-color !important;
    }

    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: $body-input-font-color !important;
  }

  &.with-border, &[type="text"].with-border, &[type="password"].with-border, &[type="email"].with-border, &[type="number"].with-border {
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
  }
}

/* With Border */

textarea.with-border, select.with-border {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
}

input {
  &.with-border:focus, &[type="text"].with-border:focus, &[type="password"].with-border:focus, &[type="email"].with-border:focus, &[type="number"].with-border:focus {
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08);
  }
}

textarea.with-border:focus {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08);
}

input {
  &[type="submit"] {
    border: none;
    padding: 11px 18px;
    width: auto;
  }

  &[type="checkbox"] {
    display: inline;
  }

  &[type="radio"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    box-shadow: none;
  }
}

/* Input Placeholder Color */

::placeholder {
  color: $body-input-font-color;
  opacity: 1;
}

textarea {
  height: auto;
  line-height: $body-line-height;
  padding: 20px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}

label, legend {
  display: block;
  font-weight: bold;
  font-size: $body-font-size;
  font-weight: normal;
  margin-bottom: 8px;
}

label span, legend span {
  font-weight: normal;
  font-size: 14px;
  color: #444;
}

fieldset {
  padding: 0;
  border: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
}

a {
  color: $primary-color;
  transition: 0.3s;
  outline: none !important;
}

button {
  outline: none !important;
}

a:not(.btn) {
  &:focus, &:hover {
    text-decoration: none;
    color: $body-heading-color;
  }
}

img {
  max-width: 100%;
}


/* ---------------------------------- */
/* Input With Icon
------------------------------------- */

.input-with-icon {
  display: block;
  position: relative;

  input {
    padding-right: 45px;
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    font-size: 20px;
    color: #a0a0a0;
    font-style: normal;
    cursor: normal;
    pointer-events: none;

    &.currency {
      font-size: 14px;
    }
  }
}

/* Input with boxed icon */

.input-with-icon-left {
  position: relative;

  input {
    padding-left: 65px;
  }

  i {
    position: absolute;
    top: 0;
    color: #fff;
    text-align: center;
    line-height: 38px;
    width: 38px;
    height: 38px;
    font-size: 19px;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    display: block;
    border-radius: $global-border-radius 0 0 $global-border-radius;;
  }

  &.size-x2 {
    i {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 28px;
    }

    input {
      padding-left: 70px !important;
    }
  }

  &.no-border i {
    border: none;
  }
}

/* ---------------------------------- */
/* Keywords Input
------------------------------------- */

.keywords-container {
  margin-bottom: -15px;
}

.keywords-list {
  display: block;
  float: left;
  height: 0;
}

.keyword {
  background-color: rgba($primary-color, 0.07);
  color: $primary-color;
  display: inline-block;
  float: left;
  padding: 0;
  border-radius: $global-border-radius;
  cursor: default;
  margin: 0 7px 7px 0;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  animation: KeywordIn 0.3s ease-in-out;
  animation-fill-mode: both;
  transition: 0.4s;
  overflow: hidden;
  max-width: 100%;
  font-size: 14.7px;

  &.keyword-removed {
    max-width: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
    animation: KeywordOut 0.4s ease-in-out;
    animation-fill-mode: both;
    pointer-events: none;
  }
}

@keyframes KeywordIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}


@keyframes KeywordOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    margin-top: -35px;
  }
}


.keyword-text {
  padding-right: 12px;
}

.keyword .keyword-remove:before {
  display: inline-block;
  cursor: pointer;
  font-family: "Feather-Icons";
  content: "\ea02";
  line-height: 20px;
  position: relative;
  top: 2px;
  margin-right: 4px;
  margin-left: 10px;
  font-size: 15px;
}

.keyword-input-container {
  position: relative;

  .keyword-input-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    width: 38px;
    padding: 0;
    color: #fff;
    background-color: $primary-color;
    border-radius: $global-border-radius;
    margin: 6px;
    font-size: 19px;
    text-align: center;
    line-height: 38px;

    i {
      color: #fff;
      position: relative;
      top: 2px;
    }
  }
}

input.keyword-input {
  margin: 0 0 15px 0;
}

label {
  margin-bottom: 5px !important;
}

body {
  input,input.form-control{
    height: 38px;
    line-height: 38px;
  }

  input, textarea, input.form-control, textarea.form-control {
    &.size-x2 {
      height: 60px;
      line-height: 60px;
      font-size: 28px;
    }

    padding: 0 15px;
    outline: none;
    font-size: $body-font-size;
    color: $body-input-font-color;
    margin: 0 0 10px 0;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    font-weight: 500;
    opacity: 1;
    border-radius: $global-border-radius;
    border: 1px solid hsl(0, 0%, 80%);
    box-shadow: none;
  }
}