textarea.form-control {
  line-height: 22px !important;
}
.select-option-label{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  margin-bottom: 0 !important;
}
