// Badge Mixins
//
// This is a custom mixin for badge-#{color}-soft variant of Bootstrap's .badge class

@mixin badge-variant-soft($bg, $color) {
  color: $color !important;;
  background-color: $bg !important;

  &[href] {
    @include hover-focus {
      color: $color !important;;
      text-decoration: none;
      background-color: darken($bg, 5%) !important;
    }
  }
}
