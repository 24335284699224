/* ---------------------------------- */
/* Notify Box
------------------------------------- */

.notify-box {
  display: block;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: $global-border-radius;
  padding: 15px 25px;
  position: relative;
  line-height: 28px;
  min-height: 59px;
  label {
    margin-bottom: 0;
    cursor: pointer;
  }
  .switch-button {
    margin-right: 10px;
  }
}

.sort-by {
  .bootstrap-select {
    position: relative;
    right: 0;
    top: 0;
  }
  .dropdown-menu {
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.14);
  }
  .bootstrap-select {
    &.btn-group button {
      flex: 1;
      position: relative;
      right: 0;
      top: 4px;
      font-weight: 600;
      margin-left: 10px;
      width: auto;
      padding: 0;
      padding-right: 12px;
      background-color: transparent;
      box-shadow: none;
      height: 20px;
      color: $body-heading-color;
    }
    &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: auto;
    }
    &.btn-group .dropdown-toggle .caret {
      right: 0;
    }
    .dropdown-menu {
      padding-top: 15px;
      top: 40px;
      right: 0;
      left: auto;
      position: absolute;
      min-width: 160px !important;
      flex: 1;
    }
    &.open:before {
      display: none;
    }
  }
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  float: right;
}

/* Notify Box Media Queries*/
@media (max-width: 768px) {
  .notify-box {
    margin-bottom: 90px;
    .sort-by {
      position: absolute;
      margin-top: 35px;
      left: 0;
      .bootstrap-select .dropdown-menu {
        left: 0;
        right: auto;
      }
    }
  }
}