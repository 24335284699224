.admin-orders {
  tbody {
    td:not(.CalendarDay) {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 50px;

      .actions-cell {
        margin-top: 13px;
        .btn{
          line-height: 25px;
        }
      }

      .order-status-view {
        margin-top: 11px;
      }
    }
  }
}
.order-single-page {
  .order-total-input {
    width: 250px;
    background: transparent none repeat scroll 0% 0%;
    box-shadow: 0px 0px 0px;
    border-color: currentcolor currentcolor rgb(226, 226, 226);
    border-style: none none solid;
    border-width: 0px 0px 3px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px;
    font-size: 32px;
    text-align: right;
  }
}

#order-short-link {
  background: transparent;
  box-shadow: 0 0 0;
  border: 0 none;
  width: calc(100% - 50px);
  height: auto;
  line-height: 35px;
  float: left;
  margin-bottom: 0;
}

#invoice-right-info {
  .order-status-view {
    float: right;
  }
}

.transaction-actions{
  svg{
    margin-top: -3px;
    vertical-align: auto;
  }
}