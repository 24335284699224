.dzu-dropzone {
  overflow: hidden !important;
  display: block;
  text-align: center;
  min-height: 160px !important;
  border-style: dashed !important;

  .dzu-inputLabel {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  input.dzu-input {
    width: 260px;
    display: block;
    margin: 20px auto;
  }

  .dzu-submitButtonContainer {
    display: none;
  }

  .dzu-previewContainer {
    border-bottom: 0 none;
  }
}