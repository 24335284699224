#root {
  position: relative;
  z-index: 2;
}

body {
  #header-container {
    z-index: 999999;
  }
}

#header {
  .header-title {
    display: inline-block;
    line-height: 80px;
    border-left: 1px solid #f1f1f1;
    padding-left: 50px;
  }
}

.main-page-container {
  min-height: calc(100vh - 160px);
}

.dashboard-content-container {
  //position: fixed;
  //right: 0;
  //bottom: 0;
  padding-left: 275px;
  @media screen and (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
}

.dashboard-sidebar {
  //height: calc(100vh - 80px);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  //top: 80px;
  width: 280px;
  border-right: 2px solid #CCC;
  @media screen and (max-width: 600px) {
    width: 100% !important;
    top: 0 !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: .5;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: not-allowed;
}

.price-view {
  font-weight: 900;
  font-family: 'Inconsolata', monospace;
  letter-spacing: -1px;
  font-size: 110%;
}

/*auto complete*/
.autocomplete-suggestions {
  margin-top: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #999;
  background: #FFF;
  cursor: default;
  overflow: auto;
  -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
  -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
  box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
}

.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-no-suggestion {
  padding: 2px 5px;
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: bold;
  color: #000;
}

.autocomplete-group {
  padding: 2px 5px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  display: block;
  border-bottom: 1px solid #000;
}

/*loading*/
.loading-div {
  background-color: rgba(255, 255, 255, .85);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  display: none;

  .gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: #fff;
    filter: contrast(20);

    .dot {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      left: 15px;
      filter: blur(4px);
      background: #000;
      border-radius: 50%;
      transform: translateX(0);
      animation: dot 2.8s infinite;
    }

    .dots {
      transform: translateX(0);
      margin-top: 12px;
      margin-left: 31px;
      animation: dots 2.8s infinite;

      span {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
      }
    }
  }
}

body.loading, body.ajax-on {
  .loading-div {
    display: inherit;
  }
}

@-moz-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@-webkit-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@-o-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@-moz-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

@-webkit-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

@-o-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

.ajax-processing {
  float: right;
  padding: 10px;
  margin: 10px;
  background: #FFF;
  border-radius: 10px;
  color: #333;
  border: 2px solid #CCC;

  span {
    float: right;
    margin-left: 30px;
    font-size: 14px;
    line-height: 15px;
  }
}

/*css spin*/
.cssspin {
  animation-name: cssspin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes cssspin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*order status*/
.order-status-view {
  span {
    border: 2px solid rgba(0, 0, 0, .1);
    color: #fff;
    width: 80px;
    padding: 0 5px;
    line-height: 23px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    display: inline-block;

    .text {
      float: left;
      line-height: 25px;
      width: 100%;
    }

    i {
    }

    &:hover {
      i {
        background-color: rgba(0, 0, 0, .35);
      }
    }
  }

  i {
    transition: all .2s;
    margin-left: 5px;
    float: right;
    padding: 3px;
    border-radius: 3px;
    margin-top: -1.5px;
    background: rgba(0, 0, 0, .1);

    &:before {
      font-size: 20px !important;
    }
  }
}

.font-size-18 {
  font-size: 18px;
}

.transaction-gw-logo {
  width: 110px;
  height: 30px;
  background-size: 75% !important;
  margin: 0 auto;
}

.text-underline {
  text-decoration: underline;
}

.admin-table {
  tbody {
    td {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

/*input verification*/
.gl-input-verification-group {
  position: relative;

  .gl-input-error-warning {
    position: absolute;
    bottom: -10px;
    right: 0;
    background: $danger;
    color: #fff !important;
    padding: 2px;
    border-radius: 3px;
    font-size: 11px;
  }
}

.fullh {
  min-height: 100vh;
}

.fullh80 {
  min-height: calc(100vh - 80px);
}

.verification-code-input {
  font-size: 32px !important;
  letter-spacing: 20px !important;
  text-align: center;
  border: 2px solid #f5f5f5 !important;
  padding: 30px !important;
}

.paymendo-grad {
  background-image: linear-gradient(to right, #1f5274 0%, #35779a 51%, #1f5274 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  transition: 0.5s !important;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
}
.btn-paymendo-grad {
  background-image: linear-gradient(to right, #1f5274 0%, #35779a 51%, #1f5274 100%);
  transition: 0.5s !important;
  background-size: 200% auto;
  color: white;
  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
}

.shortcode-single {
  width: calc(50% - 5px);
  margin-left: 2.5px;
  margin-right: 2.5px;
  margin-bottom: 2.5px;
  cursor: pointer;
  border-bottom: 2px solid #fff;
  transition: all .2s ease-in-out;
  background-color: #e5e5e5;
  border-radius: 2.5px;

  &:hover {
    border-color: $primary-color;
  }

  &:nth-child(2n+1) {
    background-color: #f5f5f5;
  }
}

.text-excepted {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //width: 100%;
  //float: left;
}

.cursor-pointer {
  cursor: pointer;
}

.input-with-icon-left {
  input {
    padding-left: 60px !important;
  }
}

.MuiTypography-body1 {
  font-weight: 300 !important;
  font-size: 12px !important;
}

.MuiSwitch-root {
  input {
    display: none;
  }
}

/*snackbar*/
div[class^="Snackbar_snackbar-wrapper"] {
  z-index: 9999999;
}

input {
  &.disabled {
    background-color: #e4e4e4;
  }
}

.admin-logged {
  z-index: 999;
  padding: 5px 10px;
  background: #000;
  color: #fff;
  font-size: 30px;
  border-radius: 0 4px 4px 0;
  position: fixed;
  bottom: 40px;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.client-dashboard-icon {
  font-size: 50px;
  position: absolute;
  top: 0;
  right: 20px;
  opacity: .3;
}

.no-icon {
  i {
    display: none;
  }
}

a.dashboard-responsive-nav-trigger {
  border-radius: 0;

  &:hover, &:focus {
    color: #eee;
  }
}

#client-menu-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  z-index: 100;
  transition: all .2s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.email-tag {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  width: calc(100% - 50px);

}

[id^='filter'] {
  .radio label {
    margin: 1px 0;
  }
}

.address-lines-sidebar {
  display: none;
}

.income-icon, .refund-icon {
  font-size: 27px;
}

.text-dotdot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 80px);
}

.client-profile-url {
  text-decoration: underline;
}

label {
  &.error {
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.width-auto {
  width: auto;
}

.order-mark-group {
  .btn {
    padding-left: 6px;
    padding-right: 6px;
    width: 30px;
    height: 30px;
    line-height: 10px;
  }
}

.client-div {
  .gl-table-footer {
    position: fixed;
    bottom: 67px;
    left: 50%;
    width: 1140px;
    margin-left: calc(-1140px / 2);
  }
}