
/* ---------------------------------- */
/* Simlpebar.js (custom scrollbar)
------------------------------------- */

[data-simplebar] {
  position: relative;
  z-index: 0;
  //overflow: hidden !important;
  max-height: inherit;
  -webkit-overflow-scrolling: touch;
  /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

[data-simplebar="init"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.simplebar-scroll-content {
  overflow-x: hidden !important;
  overflow-y: scroll;
  min-width: 100% !important;
  max-height: inherit !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.simplebar-content {
  overflow-y: hidden !important;
  overflow-x: scroll;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-height: 100% !important;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11px;
  height: calc(100% - 6px);
  margin-top: 6px;
}

.simplebar-scrollbar {
  position: absolute;
  right: 6px;
  width: 5px;
  min-height: 10px;
  &:before {
    position: absolute;
    content: "";
    background: black;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s, background-color 0.3s;
    background-color: #d8d8d8;
  }
  &:hover:before {
    background-color: #c8c8c8;
  }
}

.simplebar-track {
  &:hover .simplebar-scrollbar:before, .simplebar-scrollbar.visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    -webkit-transition: opacity 0 linear;
    transition: opacity 0 linear;
  }
  &.vertical {
    top: 0;
    .simplebar-scrollbar:before {
      top: 2px;
      bottom: 2px;
    }
  }
  &.horizontal {
    left: 0;
    width: auto;
    height: 11px;
    display: none;
    .simplebar-scrollbar {
      &:before {
        height: 100%;
        left: 2px;
        right: 2px;
      }
      right: auto;
      top: 2px;
      height: 7px;
      min-height: 0;
      min-width: 10px;
      width: auto;
    }
  }
}

body.gray .dashboard-content-container .simplebar-scrollbar {
  &:before {
    background-color: #c9c9c9;
  }
  &:hover:before {
    background-color: #b9b9b9;
  }
}

.dashboard-box {
  .simplebar-scrollbar:before {
    background-color: #d8d8d8;
  }
  .simplebar-scrollbarLhover:before {
    background-color: #c8c8c8;
  }
}