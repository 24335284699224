.single-client-box {
  padding: 0;
  background-color: #f5f5f5;
  margin: 0;
  height: 400px;
  position: relative;

  h4 {
    line-height: 45px;
    text-transform: uppercase;
  }

  & > table {
    margin: 0 10px;
    width: calc(100% - 20px);
    background-color: #fff;

    tr {
      &:nth-child(2n+2) {
        background-color: #e5e5e5;
      }
    }

    td, th {
      text-overflow: ellipsis;
      //white-space: nowrap;
      padding-left: 5px;
      padding-right: 5px;
      //overflow: hidden;
    }

    th {
      text-align: right;
    }

    .apexcharts-canvas {
      margin: 0 auto;
    }

    table {
      tr {
        span {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &#cp-email, #cp-phone {
            max-width: calc(100% - 25px);
          }

          &#cp-emailverify, &#cp-smsverify {
            width: 16px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.musteri-grubu-select {
  .select2-container--default .select2-selection--single {
    height: auto;

    &:not([aria-expanded="true"]) {
      background: transparent;
      border: none;
      box-shadow: 0 0 0;
      border-radius: 0;
    }
  }

  .select2-selection.select2-selection--single {
    border: 0 none;
    box-shadow: 0 0 0;
  }

  .select2-container {
    .selection {
      width: 100%;
    }
  }

  .select2-selection__clear {
    float: left !important;
    margin-right: 10px;
    width: 22px;
    text-align: center;
    background: #dc3545;
    color: #fff;
    height: 22px;
    line-height: 20px;
    border-radius: 100%;
    font-size: 18px;
    margin-top: 9px;

    transition: .2s ease-in-out;

    &:hover {
      background-color: #c82333;
    }
  }
}

.client-stats-table {
  tr {
    td, th {
      padding: 2px 0;
    }
  }
}

.admin-clients-page {
  tbody {
    td {
      .price-view {
        font-size: 18px;
      }
    }
  }
}

.user-invoices-box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.client-div {
  background-image: linear-gradient(to top, #f2f2f2 0%, #e2ebf0 100%);
  height: 100vh;
}

//.message-history-widget {
//  .nav {
//    .active {
//      background-color: $light;
//      border-top: 2px solid $dark;
//      margin-top: -2px;
//      border-bottom: 2px solid $dark;
//
//      a {
//        color: $dark;
//      }
//    }
//  }
//}

//.single-sms-history {
//  transition: 0.2s all ease-in-out;
//
//  &:hover {
//    background-color: #f5f5f5;
//  }
//}

.small-profile-card,
.expanded-profile-card {
  padding-top: 20px;
  padding-bottom: 20px;

  .verified-badge-with-title {
    top: 4px;
  }

  &.expanded-profile-card {
    div {
      span.icon {
        width: 200px;
        text-align: left;
        display: inline-block;
        font-weight: bold;
      }

      span.text {
        &:before {
          content: ":";
          position: absolute;
          left: -10px;
          top: 0;
          line-height: 32px;
        }
      }
    }

    & > div {
      &:nth-child(2n+2) {
        background-color: #f7f7f7;
      }
    }
  }

  div {
    padding-left: 15px;
    line-height: 32px;
    font-size: 17px;

    span.icon {
      opacity: .6;
    }

    span.text {
      display: inline-block;
      position: relative;
      margin-left: 10px;
    }
  }
}

.client-detail-orders-table {
  .gl-table-header {
    background-color: #f8f9fa;
  }
}