
/* ---------------------------------- */
/* Verified Badge
------------------------------------- */

.job-listing-company .verified-badge, .job-listing .job-listing-footer ul li .verified-badge {
  position: relative;
  display: inline-block;
  transform: scale(0.7);
  top: 2px;
  margin-left: -2px;
}

/* Verified Badge */

.verified-badge {
  position: relative;
  height: 25px;
  width: 25px;
  display: inline-block;
  background-color: #38b653;
  border-radius: 50%;
  text-align: center;
  z-index: 10;
  font-weight: 500;
  &:before {
    content: "\e92b";
    font-family: "Feather-Icons";
    font-size: $body-font-size;
    color: #fff;
    position: relative;
    top: 0px;
    line-height: $body-line-height;
  }
}

.job-listing .job-listing-company-logo .verified-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(7px, 7px);
}

/* Long Verified Badge */

.verified-badge-with-title {
  position: relative;
  height: 26px;
  line-height: 26px;
  display: flex;
  top: -1px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background-color: #30ab4a;
  text-align: center;
  z-index: 10;
  font-weight: 500;
  border-radius: $global-border-radius;
  padding: 0 8px 0 0;
  margin: 0;
  overflow: hidden;
  padding-left: 34px;
  &:before {
    content: "\e92b";
    font-family: "Feather-Icons";
    font-size: $body-font-size;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    line-height: $body-sub-line-height;
    height: 26px;
    width: 26px;
    display: inline-block;
    background-color: #38b653;
  }
  &.bg-danger{
    &:before{
      content: "\e9d0";
      background-color: #f74f5f !important;
    }
  }
}