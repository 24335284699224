
/* ---------------------------------- */
/* Notification Boxes
------------------------------------- */

#result .success {
  line-height: 24px;
  margin-bottom: 15px;
  position: relative;
  padding: 20px 26px;
  padding-right: 50px;
  border-radius: 3px;
}

.notification {
  line-height: 24px;
  margin-bottom: 15px;
  position: relative;
  padding: 20px 26px;
  padding-right: 50px;
  border-radius: 3px;
  p {
    margin: 0;
    font-size: 15px;
  }
}

#result .success, .notification.success {
  background-color: #EBF6E0;
}

#result .success {
  color: #5f9025;
}

.notification {
  &.success {
    color: #5f9025;
    a, strong {
      color: #5f9025;
    }
  }
  &.error {
    background-color: #ffe9e9;
    color: #de5959;
    a, strong {
      color: #de5959;
    }
  }
  &.warning {
    background-color: #FBFADD;
    color: #8f872e;
    a, strong {
      color: #8f872e;
    }
  }
  &.notice {
    h4 {
      font-size: 19px;
      margin: 3px 0 15px 0;
      color: #3184ae;
    }
    color: #3184ae;
    a, strong {
      color: #3184ae;
    }
    background-color: #E9F7FE;
    &.large {
      padding: 32px 36px;
    }
  }
  strong {
    font-weight: 700;
  }
  a {
    text-decoration: underline;
  }
}

body .notification strong {
  border: none;
}

.notification {
  &.success .close, &.error .close, &.warning .close {
    padding: 0px 9px;
    position: absolute;
    right: 0;
    top: 22px;
    display: block;
    height: 8px;
    width: 8px;
    cursor: pointer;
  }
  &.notice {
    .close {
      padding: 0px 9px;
      position: absolute;
      right: 0;
      top: 22px;
      display: block;
      height: 8px;
      width: 8px;
      cursor: pointer;
    }
    p span i {
      font-weight: 500;
    }
  }
  a.button {
    float: right;
    color: #fff;
    margin-top: 3px;
  }
  &.notice a.button {
    background-color: #388fc5;
  }
  &.warning a.button {
    background-color: #dfbe51;
  }
  &.error a.button {
    background-color: #d34c4c;
  }
  &.success a.button {
    background-color: #79ba38;
  }
  &.closeable a.close:before {
    content: "\ea02";
    font-family: "Feather-Icons";
    position: absolute;
    right: 25px;
    top: 0;
    cursor: pointer;
    font-weight: 600;
  }
}
