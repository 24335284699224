.message-content {
  pointer-events: none;
  cursor: not-allowed;

  table {
    tr {
      border-bottom: 0;
      border-top: 0;

      td, th {
        border-bottom: 0;
        border-top: 0;
      }
    }
  }
}
.page-content-editor {
  .ck-editor__editable_inline {
    height: 500px;
  }
}

.quick-mail-editor {
  .ck-editor__editable_inline {
    min-height: 250px;
  }
}