
/* ---------------------------------- */
/* Custom Upload Button
------------------------------------- */

.uploadButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
  font-style: normal;
  font-size: 14px;
  .uploadButton-input {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
  }
  .uploadButton-button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 44px;
    padding: 10px 18px;
    cursor: pointer;
    border-radius: $global-border-radius;
    color: $primary-color;
    background-color: transparent;
    border: 1px solid $primary-color;
    flex-direction: row;
    transition: 0.3s;
    margin: 0;
    outline: none;
    box-shadow: 0 3px 10px rgba($primary-color, 0.1);
    &:hover {
      background-color: $primary-color;
      box-shadow: 0 4px 12px rgba($primary-color, 0.15);
      color: #fff;
    }
  }
  .uploadButton-file-name {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    padding: 0 10px;
    padding-left: 18px;
    min-height: 42px;
    top: 1px;
    position: relative;
    color: #888;
    background-color: transparent;
    overflow: hidden;
    line-height: 22px;
  }
}